import React, { useEffect, useState } from "react";
import url from "../../assets/image1modipm.png";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import SecHead from "../SectionHeader/SecHead";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { topStories } from "../../redux/actions/privateApiAction";
import ResponseModel from "../CustomModel/ResponseModel";
import oppsmsg from "../../assets/response-icon/oppsmsg.svg";
import loader from "../../assets/loader.gif";
import DetailShimmer from "../../common/DetailShimmer";
import Shimmer from "../../common/Shimmer";

const TopStoriesHomeComp = () => {
  // console.log("checkToken", checkToken);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [homePageData, setHomePageData] = useState([]);

  const { topStoriesData } = useSelector((state) => state.privateReducer);

  const isVerified = localStorage.getItem("accessToken");

  useEffect(() => {
    // if (!isVerified) {
    //   dispatch(getPublicPost());
    // } else {
    // TODO: top stories api
    // setLoading(true);
    dispatch(topStories(6)).then(() => {
      setLoading(false);
    });
    // }
  }, [dispatch]);

  useEffect(() => {
    // if (!isVerified) {
    //   setHomePageData(publicPost);
    // } else {
    setHomePageData(topStoriesData);
    // }
  }, [topStoriesData]);
  // console.log("homePageData", homePageData);

  return (
    <>
      <div className="block md:hidden">
        {Array.isArray(homePageData) && homePageData.length > 0 ? (
          <Link
            to={isVerified ? `/top-stories/${homePageData[0]?.slug}` : "/login"}
          >
            <div
              key={homePageData?.[0]?.id}
              className="relative rounded-2xl overflow-hidden bg-cover bg-center text-white"
              style={{
                backgroundImage: `url(${homePageData?.[0]?.file})`,
                height: "16rem",
              }}
            >
              <span className="absolute text-sm font-semibold text-white p-4">
                <CategoryPolls
                  key={homePageData[0]?.category?.id}
                  title={homePageData[0]?.category?.name}
                  postid={homePageData[0]?.category?.id}
                  img={homePageData[0]?.category?.icon}
                />
              </span>
              <div className="absolute inset-0 bg-black bg-opacity-10 flex flex-col justify-end">
                <div
                  className="flex flex-col px-4 p-2"
                  style={{ background: "#000", opacity: "0.4" }}
                >
                  <h3 className="title_shown py-2" style={{ color: "#fff" }}>
                    {homePageData[0]?.title}
                  </h3>
                  <span className="date_shown" style={{ color: "#fff" }}>
                    {moment(homePageData[0]?.updatedAt).format("MMMM D, YYYY")}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ) : (
          <div>
            <div className="flex flex-col justify-center items-center">
              <div className="skeleton-loader w-full h-[16rem] rounded-xl p-3"></div>
              {/* <div className="object-contain ">
                <img src={loader} alt="loading..." />
              </div> */}
            </div>
          </div>
        )}
      </div>

      {/* mobile view   */}
      <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5 md:hidden">
        <SecHead title={"Top Stories"} redirectLink="/top-stories" />
        <div
          className="flex space-x-4 overflow-x-scroll scrollbar-hide pt-3"
          style={{
            height: "11rem",
          }}
        >
          {!loading &&
          Array.isArray(homePageData) &&
          homePageData.length > 0 ? (
            homePageData?.map((post) => (
              <div
                key={post?.uuid}
                className="flex-shrink-0 w-64 h-40 relative rounded-lg overflow-hidden bg-cover bg-center"
              >
                <Link
                  to={isVerified ? `/top-stories/${post?.slug}` : "/login"}
                  key={post?.id}
                  className="absolute inset-0  bg-center bg-cover "
                  style={{
                    backgroundImage: `url(${post?.file})`,
                    backgroundRepeat: "none",
                  }}
                >
                  <div className="absolute inset-0 bg-black bg-opacity-10 flex flex-col justify-end">
                    <div
                      className="flex flex-col px-4 p-2"
                      style={{ background: "#000", opacity: "0.4" }}
                    >
                      <h3 className="subtitle_shown " style={{ color: "#fff" }}>
                        {post?.title}
                      </h3>

                      <span className="date_shown" style={{ color: "#fff" }}>
                        {moment(post?.updatedAt).format("MMMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <>
              <div className="flex-shrink-0 w-64 h-40 relative rounded-lg overflow-hidden bg-cover bg-center skeleton-loader"></div>
              <div className="flex-shrink-0 w-64 h-40 relative rounded-lg overflow-hidden bg-cover bg-center skeleton-loader"></div>
            </>
            // <ResponseModel
            //   iconRes={oppsmsg}
            //   msgRes="Oops!"
            //   textRes="Nothing to show..."
            //   isPage={true}
            // />
          )}
        </div>
      </div>
      {/* Web view: Display all stories */}
      {!loading ? (
        <div className="bg-white w-full  p-3 md:p-5 hidden md:block rounded-lg">
          <SecHead title={"Top Stories"} redirectLink="/top-stories" />
          {Array.isArray(homePageData) && homePageData.length > 0 ? (
            homePageData.map((post) => (
              <Link
                to={isVerified ? `/top-stories/${post?.slug}` : "/login"}
                key={post?.id}
                className="flex flex-col md:flex-row items-start gap-5 py-4  relative"
              >
                <img
                  src={post?.file}
                  alt={post?.title}
                  className="w-full md:w-[14rem] object-cover rounded-md"
                  style={{
                    height: "9rem",
                    minWidth: "250px",
                  }}
                />
                <div className="flex flex-col gap-2 opacity-90">
                  <CategoryPolls
                    key={post?.category?.id}
                    title={post?.category?.name}
                    postid={post?.category?.id}
                    img={post?.category?.icon}
                  />
                  <span
                    className="text-[16px] text-gray-800 font-semibold  tracking-normal"
                    style={{ lineHeight: 1.4 }}
                  >
                    {post?.title}
                  </span>
                  <span className=" date_shown absolute bottom-3">
                    {moment(post?.updatedAt).format("MMMM D, YYYY")}
                  </span>
                </div>
              </Link>
            ))
          ) : (
            <div className="min-h-[80vh] bg-white">
              <div className="flex flex-col h-[70vh] justify-center items-center">
                <div className="object-contain ">
                  <img src={loader} alt="loading..." />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div
            className="min-h-[80vh] bg-white rounded-lg hidden md:block"
            style={{
              minWidth: "38rem",
            }}
          >
            <div className="min-h-[70vh] py-4">
              <div className="px-6">
                <SecHead title={"Top Stories"} redirectLink="/top-stories" />
              </div>
              {Array(5)
                ?.fill(null)
                ?.map((res) => {
                  return <Shimmer />;
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TopStoriesHomeComp;
