import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
import { Link, useNavigate, useParams } from "react-router-dom";
import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import { useEffect, useState } from "react";
import { globalSearch } from "../../redux/actions/publicAction";
import oppnothingFoundsmsg from "../../assets/icons/nothingFound.svg";

function SearchResult() {
  const id = useParams();
  const navigator = useNavigate();
  // console.log("SearchResultid",id?.slug);

  const dispatch = useDispatch();
  const [serchedData, setSearchedData] = useState();
  const { globalsearch } = useSelector((state) => state.publicReducer);
  const matchedsearch = localStorage.getItem("search");
  const isVerified = localStorage.getItem("accessToken");

  window.scrollTo(0, 0);

  // console.log("matchedsearch",matchedsearch);

  useEffect(() => {
    if (isVerified) {
      if (id?.slug === matchedsearch) {
        dispatch(globalSearch(id?.slug));
      }
    } else {
      navigator("/login");
    }
  }, [id?.slug, isVerified]);

  console.log("serchedData ,", serchedData);

  const breadCumb = [
    { id: 1, name: "Home", link: "/" },
    {
      id: 2,
      name: "Search",
      link: "/",
    },
    {
      id: 2,
      name: "Search Results",
      link: `/search/search-results/${id?.slug}`,
    },
  ];
  useEffect(() => {
    if (globalsearch?.length) {
      setSearchedData(globalsearch);
    }
  }, []);
  return (
    <>
      <div className="bg_home bg-[#F7F8FA] md:bg-[#ffffff] flex flex-col ">
        <div className="">
          <div className="md:hidden my-4 px-4">
            {" "}
            <PreSectionHeader title={"Search Results for"} /> {id?.slug}
          </div>
          <hr className="border-b-1 md:border-none mt-3 md:mt-0 font-semibold" />

          {/* Web view: Display all stories */}
          <div className="bg_home md:bg-[#F7F8FA]  px-0 min-[840px]:px-40 flex flex-col min-[840px]:pb-14 md:py-0">
            <div className="flex flex-row items-center py-5">
              {breadCumb.map((item, index) => (
                <span
                  key={item.id}
                  className={`text-[10px] font-medium  ${
                    index === breadCumb.length - 1 ? "" : "opacity-60"
                  }`}
                >
                  {index > 0 && <span className="mx-1">&gt;</span>}
                  <Link to={item?.link} className="breadcrumb-link">
                    {item.name}
                  </Link>
                </span>
              ))}
            </div>
            <div className="md:bg-[#FFFFFF] w-full    md:rounded-xl ">
              {globalsearch?.length ? (
                <div className="bg-white w-full  p-3 md:p-5  md:rounded-xl">
                  <h3 className="flex font-sans text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A] hidden md:block">
                    Search Results for: {id?.slug}
                  </h3>

                  <div className=" ">
                    {/* {console.log("serchedData ", serchedData)}
                    {console.log("globalsearch ", globalsearch)}
                    {console.log("matchedsearch ", matchedsearch)}
                    {console.log("id?.slug ", id?.slug)} */}
                    {Array.isArray(globalsearch) &&
                      globalsearch.map((post) => (
                        <Link
                          to={`/more-posts/${post?.slug}`}
                          key={post?.id}
                          className="flex flex-col md:flex-row items-start gap-5 py-4  relative"
                        >
                          <img
                            src={post?.file}
                            alt={post?.title}
                            className="w-full md:w-[14rem] h-36  object-cover rounded-md"
                          />
                          <div className="flex flex-col gap-1">
                            <CategoryPolls
                              key={post?.category?.id}
                              title={post?.category?.name}
                              postid={post?.category?.id}
                              img={post?.category?.icon}
                            />

                            <span className="font-sans text-[#1A1A1A] leading-tight font-medium tracking-normal">
                              {post?.title}
                            </span>
                            <span className=" date_shown relative md:absolute md:bottom-3">
                              {moment(post?.updatedAt).format("MMMM D, YYYY")}
                            </span>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="bg-white h-[60vh]">
                  <div className="flex flex-col justify-center items-center h-[50vh]">
                    <div className="">
                      <img
                        src={oppnothingFoundsmsg}
                        alt="nothing found"
                        className="w-24 h-24 object-contain"
                      />
                    </div>
                    <p className="text-2xl font-bold ">Nothing Found</p>
                    <span
                      className="my-3 transsition-all px-3 py-2 rounded-md  bg-red-600 hover:text-red-600 hover:bg-white border-2 border-red-600 text-white font-bold active:scale-95  duration-300"
                      role="button"
                      onClick={() => navigator("/")}
                    >
                      Go to Home
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SearchResult;
