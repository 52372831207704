import { POLLS_AVERAGE_DATA, POLLING_TABLE_LIST, ELECTORAL_VOTES } from "../actions/type";

// import { CATEGORY_DETAIL, FOR_YOU_DATA, GET_BACK_TO_DETAILS, GET_NAVBAR_MENULIST, GET_PUBLIC_LACAL_NEWS, GET_PUBLIC_POST, GET_PUBLIC_POST_DETAILS, GLOBAL_SEARCH, LATEST_POST, MORE_POST, PICK_FOR_YOU_DATA, POST_COMMENT, STORE_COMMENT, YOUR_TOPIC_DATA } from "../actions/type";
const initialState = {
    pollingList: [],
    pollsAverageResponse: []
    // publicPostDetails: {},
    // publicLocalNews:[],
    // menuList:[],
    // latestPost:[],
    // morepostdata:{},
    // navbarlistid:"",
    // globalsearch:"",
    // forYou:[],
    // yourTopic:[],
    // pickforyou:[],
    // postcommentres:"",
    // storedComment:"",
    // getbacktodetails:""
}

const pollingReducer = (state = initialState, action) => {
    switch (action.type) {
        case POLLING_TABLE_LIST: return { ...state, pollingList: action.payload?.data }
        case POLLS_AVERAGE_DATA: return { ...state, pollsAverageResponse: action.payload?.data }
        case ELECTORAL_VOTES: return { ...state, electoralVotesResponse: action.payload?.data }
        // case GET_PUBLIC_POST_DETAILS: return { ...state, publicPostDetails: action.payload?.data }
        // case GET_PUBLIC_LACAL_NEWS:return{...state,publicLocalNews:action.payload?.data}
        // case LATEST_POST:return{...state,latestPost:action?.payload?.data}
        // case MORE_POST:return{...state,morepostdata:action?.payload?.data}
        // case GET_NAVBAR_MENULIST:return{...state,menuList:action.payload}
        // case CATEGORY_DETAIL:return{...state,navbarlistid:action?.payload?.data}
        // case GLOBAL_SEARCH:return{...state,globalsearch:action?.payload?.data}
        // case FOR_YOU_DATA:return{...state,forYou:action?.payload?.data}
        // case YOUR_TOPIC_DATA:return{...state,yourTopic:action?.payload?.data}
        // case PICK_FOR_YOU_DATA:return{...state,pickforyou:action?.payload?.data}
        // case POST_COMMENT:return{...state,postcommentres:action?.payload?.msg}
        // case STORE_COMMENT:return{...state,storedComment:action?.payload}
        // case GET_BACK_TO_DETAILS:return{...state,getbacktodetails:action?.payload}
        default: return state;
    }
}
export default pollingReducer;