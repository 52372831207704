import React, { useEffect, useState } from "react";
import image1 from "../../assets/imagenew.png";
import SecHead from "../SectionHeader/SecHead";
import loader from "../../assets/loader.gif";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import { useDispatch, useSelector } from "react-redux";
import { getPublicLatestPost } from "../../redux/actions/publicAction";
import moment from "moment";
import { Link } from "react-router-dom";
import SideShimmer from "../../common/SideShimmer";

const LatestPostHomeComp = () => {
  const dispatch = useDispatch();
  const { latestPost } = useSelector((state) => state.publicReducer);
  const [loading, setLoading] = useState(true);

  const isVerified = localStorage.getItem("accessToken");
  useEffect(() => {
    dispatch(getPublicLatestPost(2)).finally(() => setLoading(false));
  }, []);
  // console.log("latestPost",latestPost);
  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }

  return (
    <div className="bg-white w-full r p-3 md:p-5 px-4 rounded-lg">
      <SecHead title={"Latest Post"} redirectLink="/latest-news" />
      {!loading && latestPost ? (
        <div className=" ">
          {Array.isArray(latestPost) &&
            latestPost.slice(0, 2).map((item, index) => (
              <Link
                //  to={`/latest-news-details/${item?.uuid}`}
                // to={`/latest-news/${item?.slug}`}
                to={isVerified ? `/latest-news/${item?.slug}` : "/login"}
                key={index}
              >
                <div
                  key={item.id}
                  className={`flex flex-col  items-start  gap-4 md:gap-2 py-4  ${
                    index === latestPost.length - 1 ? "border-none" : "border-b"
                  }`}
                >
                  <img
                    src={item?.file}
                    alt={item.title}
                    style={{
                      width: "100%",
                      maxHeight: "10rem",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                  {/* <img
                  src={item?.file}
                  alt={item.title}
                  className="w-full h-40 object-cover rounded-md"
                /> */}
                  <div className="flex flex-col mt-2 md:mt-0 md:pt-1 md:gap-2 md:opacity-90">
                    <CategoryPolls
                      title={item?.category?.name}
                      id={item?.category?.id}
                      img={item?.category?.icon}
                    />
                    <span
                      className=" text-gray-800 leading-[18px] text-[16px] font-semibold tracking-normal"
                      style={{ lineHeight: 1.4 }}
                    >
                      {item?.title}
                    </span>

                    <span className=" date_shown mt-4">
                      {moment(item?.updatedAt).format("MMMM D, YYYY")}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      ) : (
        // <div
        //   className=" bg-white"
        //   style={{
        //     minHeight: "80vh",
        //   }}
        // >
        //   <div
        //     className="flex flex-col justify-center items-center"
        //     style={{
        //       height: "70vh",
        //     }}
        //   >
        //     <div className="object-contain ">
        //       <img src={loader} alt="loading..." />
        //     </div>
        //   </div>
        // </div>
        <SideShimmer />
      )}
    </div>
  );
};

export default LatestPostHomeComp;
