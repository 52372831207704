import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import loader from "../../assets/loader.gif";
const WithLayout = ({ children }) => (
  <div className="default-layout bg-[#F7F8FA]">
    <div className="sticky top-0 bg-white w-full z-10">
      <Navbar />
    </div>
    {/* <main
      className=" overflow-auto"
      style={{
        minHeight: "90vh",
      }}
    >
      {children ? (
        <div className="">{children}</div>
      ) : (
        <div
          className=" bg-white"
          style={{
            minHeight: "80vh",
          }}
        >
          <div
            className="flex flex-col justify-center items-center"
            style={{
              height: "70vh",
            }}
          >
            <div className="object-contain ">
              <img src={loader} alt="loading..." />
            </div>
          </div>
        </div>
      )}
    </main> */}
    <main
      className="overflow-auto"
      style={{
        minHeight: "90vh",
      }}
    >
      {children ? (
        <div className="content-wrapper" style={{ minHeight: "80vh" }}>
          {children}
        </div>
      ) : (
        <div
          className="loader-wrapper bg-white flex justify-center items-center"
          style={{
            minHeight: "80vh",
          }}
        >
          <div className="skeleton-loader w-60 h-20 rounded-md bg-gray-300"></div>
        </div>
      )}
    </main>
    <div className="">
      <Footer />
    </div>
  </div>
);

export default WithLayout;
