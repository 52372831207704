import React, { useEffect, useState } from "react";
import SecHead from "../SectionHeader/SecHead";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pickforyouData } from "../../redux/actions/publicAction";
import moment from "moment";
import SideShimmer from "../../common/SideShimmer";

const PickForYouHomeComp = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { pickforyou } = useSelector((state) => state.publicReducer);
  const isVerified = localStorage.getItem("accessToken");
  useEffect(() => {
    dispatch(pickforyouData(4)).finally(() => setLoading(false));
  }, []);
  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    // console.log("str",str.replace(/[^a-zA-Z0-9-_]/g, ''));

    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }
  return (
    <div className="bg-white w-full p-4 md:p-5 rounded-lg shadow">
      <SecHead title={"Picks For You"} redirectLink="/picks-for-you" />
      {!loading ? (
        <div>
          {Array.isArray(pickforyou) &&
            pickforyou.map((item, index) => (
              <Link
                key={item.id} // Key should be on Link component
                to={isVerified ? `/picks-for-you/${item?.slug}` : "/login"}
                className={`flex flex-row items-start justify-between gap-8 py-4 border-b border-gray-300 ${
                  index === pickforyou.length - 1 ? "border-none" : "border-b"
                } transition `} // Added hover effect for better UX
              >
                <div className="flex flex-col">
                  <CategoryPolls
                    title={item?.category?.name}
                    id={item?.category?.id}
                    img={item?.category?.icon}
                  />
                  <span
                    className="text-[16px] text-gray-800 leading-[18px]  font-semibold  tracking-normal"
                    style={{ lineHeight: 1.4 }}
                  >
                    {item?.title}
                  </span>
                  <span className="date_shown mt-2 text-sm text-gray-500">
                    {moment(item.updatedAt).format("MMMM D, YYYY")}
                  </span>
                </div>
                <img
                  src={item?.file}
                  alt={item?.title}
                  className="w-20 h-auto object-cover rounded-md"
                />
              </Link>
            ))}
        </div>
      ) : (
        <SideShimmer />
      )}
    </div>
  );
};

export default PickForYouHomeComp;
