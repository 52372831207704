import React from "react";

const SideShimmer = () => {
  return (
    <div className=" bg-white">
      {Array(3)
        .fill(null)
        ?.map((res, index) => {
          return (
            <div className={` py-3 ${index !== 2 && "border-b"}`}>
              <div className={`flex justify-between my-4  `}>
                <div className="">
                  <div className="flex items-end gap-2">
                    <div className="skeleton-loader w-4 h-4"></div>
                    <div className="skeleton-loader h-2 w-16 rounded-md "></div>
                  </div>
                  <div className="skeleton-loader w-48 mt-3 h-3 rounded-md"></div>
                  <div className="skeleton-loader w-32 mt-3 h-3 rounded-md"></div>
                </div>
                <div className="skeleton-loader w-20 h-12 rounded-md"></div>
              </div>
              <div className="skeleton-loader w-20 h-2 rounded-md mt-6"></div>
            </div>
          );
        })}
    </div>
  );
};

export default SideShimmer;
