import React, { useEffect, useRef, useState } from "react";
import shareIcon from "../assets/icons/Path 23100.svg";
import {
  FaCopy,
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitter,
  FaWhatsappSquare,
} from "react-icons/fa";

// Example Share Button Component
const ShareButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const currentUrl = window.location.href;
  const shareLinks = {
    whatsapp: `https://wa.me/?text=${encodeURIComponent(currentUrl)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      currentUrl
    )}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      currentUrl
    )}`,
    instagram: `https://www.instagram.com/?url=${encodeURIComponent(
      currentUrl
    )}`,
  };
  const modalRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000); // Reset success message after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className="relative">
      <div
        ref={buttonRef}
        className="flex h-8 w-8 cursor-pointer z-10"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={shareIcon}
          alt="share post"
          className="border rounded-md p-2"
        />
      </div>
      {isOpen && (
        <div
          className="absolute left-0 mt-2 p-3 bg-white shadow-lg rounded-md z-50 border"
          ref={modalRef}
        >
          <ul className="space-y-2">
            <li className="hover:bg-gray-50 transition-all duration-300 hover:shadow-md p-2 rounded-lg">
              <a
                href={shareLinks.whatsapp}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <FaWhatsappSquare className="w-6 h-6 mr-2" />
                WhatsApp
              </a>
            </li>
            <li className="hover:bg-gray-50 transition-all duration-300 hover:shadow-md p-2 rounded-lg">
              <a
                href={shareLinks.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <FaFacebookSquare className="w-6 h-6 mr-2" />
                Facebook
              </a>
            </li>
            <li className="hover:bg-gray-50 transition-all duration-300 hover:shadow-md p-2 rounded-lg">
              <a
                href={shareLinks.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <FaTwitter className="w-6 h-6 mr-2" />
                Twitter
              </a>
            </li>
            <li className="hover:bg-gray-50 transition-all duration-300 hover:shadow-md p-2 rounded-lg">
              <a
                href={shareLinks.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <FaInstagramSquare className="w-6 h-6 mr-2" />
                Instagram
              </a>
            </li>
            <li className="hover:bg-gray-50 transition-all duration-300 hover:shadow-md p-2 rounded-lg">
              <button onClick={copyToClipboard} className="flex items-center">
                <FaCopy className="w-6 h-6 mr-2" />
                Copy Link
              </button>
              {copySuccess && (
                <span className="ml-2 text-green-500 text-sm">Copied!</span>
              )}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ShareButton;
