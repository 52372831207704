import React from "react";
import { Link } from "react-router-dom";
import oppnothingFoundsmsg from "../../assets/icons/nothingFound.svg";

const Notfound = () => {
  return (
    <div className="">
      <div className="flex justify-center items-center h-[80vh] flex-col">
        <div className="">
          <img src={oppnothingFoundsmsg} alt="icon" />
        </div>
        <div className="text-2xl my-2">Nothing Found</div>
        <Link to="/">
          <div className=" text-center bg-red-500 text-xl font-semibold text-white rounded-md p-3 mt-2 cursor-pointer active:scale-95 transition-all select-none">
            Go Back To Home
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Notfound;
