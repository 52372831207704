import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import image1 from "../../assets/image1modipm.png";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
import { FaSearch } from "react-icons/fa";
import rightarrow from "../../assets/icons/Path 23098.svg";
import leftarrow from "../../assets/icons/Path 23107.svg";
import UsePollPredictionComp from "../../components/UsePollPredictionComp/UsePollPredictionComp";
import VoteShare from "../../components/VoteShare/VoteShare";
import PollingChart from "../../components/PollingChart/PollingChart";
import PollingTable from "../../components/PollingTable/PollingTable";
import SecHead from "../../components/SectionHeader/SecHead";
import { useEffect, useState } from "react";
function Polling() {
  const location = useLocation();
  let { slug } = useParams();
  console.log("slug", slug);
  const isVerified = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  useEffect(() => {
    if (!isVerified) {
      navigate("/login");
    }
  }, []);

  const stories = [
    {
      id: 1,
      image: image1,
      title: "Lok Sabha Election 2024 Major Takeaways1",
      categories: "India Polls",
      content:
        "The results of 2024 Lok Sabha have come as a disappointment for the ruling BJP who were highly excited after the exit polls.",
      date: "June 11, 2024",
    },
    {
      id: 2,
      image: image1,
      title: "Lok Sabha Election 2024 Major Takeaways2",
      categories: "India Polls",
      content:
        "The results of 2024 Lok Sabha have come as a disappointment for the ruling BJP who were highly excited after the exit polls.",
      date: "June 11, 2024",
    },
    {
      id: 3,
      image: image1,
      title: "Lok Sabha Election 2024 Major Takeaways3",
      categories: "India Polls",
      content:
        "The results of 2024 Lok Sabha have come as a disappointment for the ruling BJP who were highly excited after the exit polls.",
      date: "June 11, 2024",
    },
    {
      id: 4,
      image: image1,
      title: "Lok Sabha Election 2024 Major Takeaways3",
      categories: "India Polls",
      content:
        "The results of 2024 Lok Sabha have come as a disappointment for the ruling BJP who were highly excited after the exit polls.",
      date: "June 11, 2024",
    },
    {
      id: 5,
      image: image1,
      title: "Lok Sabha Election 2024 Major Takeaways3",
      categories: "India Polls",
      content:
        "The results of 2024 Lok Sabha have come as a disappointment for the ruling BJP who were highly excited after the exit polls.",
      date: "June 11, 2024",
    },
    {
      id: 5,
      image: image1,
      title: "Lok Sabha Election 2024 Major Takeaways3",
      categories: "India Polls",
      content:
        "The results of 2024 Lok Sabha have come as a disappointment for the ruling BJP who were highly excited after the exit polls.",
      date: "June 11, 2024",
    },
  ];

  const posts = [
    {
      id: 1,
      image: image1,
      category: "India Polls",
      title: "PM-Kisan Samman Nidhi Yojana: Voter Coverage",
      date: "June 11, 2024",
    },
    {
      id: 2,
      image: image1,
      category: "India Polls",
      title: "PM-Kisan Samman Nidhi Yojana: Voter Coverage",
      date: "June 11, 2024",
    },
    {
      id: 3,
      image: image1,
      category: "India Polls",
      title: "PM-Kisan Samman Nidhi Yojana: Voter Coverage",
      date: "June 11, 2024",
    },
  ];

  const [voteType, setVoteType] = useState("popular");

  const date = new Date("2024-08-12T14:55:00");
  const options = {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate =
    date.toLocaleString("en-US", options).toUpperCase().replace(",", "") +
    " AT " +
    date
      .toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .toUpperCase();

  // console.log(formattedDate);

  return (
    <>
      <div className="bg_home flex flex-col pb-8">
        <div className="flex flex-row px-6 md:px-40 gap-4 text-[12px] font-semibold py-2 bg-[#E2E7EE]">
          <Link
            to={`/${slug}/general-stories`}
            className={` ${
              location.pathname === `/${slug}/general-stories`
                ? "font-bold"
                : "text-[#7F8FA4]"
            }`}
          >
            General Stories
          </Link>
          <Link
            to={`/${slug}/polling`}
            className={` ${
              location.pathname === `/${slug}/polling`
                ? "font-bold"
                : "text-[#7F8FA4]"
            }`}
          >
            Polls
          </Link>
        </div>
        <div className="bg_home md:bg-[#F7F8FA] px-4  md:px-40 flex flex-col pb-6 md:py-5">
          {/* latest polls start */}
          {/* <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5 my-6">
            <div className="flex flex-row justify-between items-center ">
              <div className="flex flex-col">
                <div className="text-[22px] pb-1">Latest Polls</div>
                <div className="text-[10px] text-gray-500">
                  Updated {formattedDate}
                </div>
              </div>
              <div className="flex items-center bg-gray-100 rounded-md gap-2 px-4 py-3 flex-grow max-w-[50rem]">
                <FaSearch className="text-gray-500" />
                <div className="h-[10px] w-[1px] bg-gray-300" />
                <input
                  type="search"
                  placeholder="Search Keyword"
                  className="border-none outline-none text-xs bg-transparent w-full"
                />
              </div>
              <div className="flex items-center gap-5">
                <button className="text-sm text-gray-600">
                  <img src={leftarrow} alt="" className=" h-[10px]" />
                </button>
                <button className="text-sm text-gray-600 ml-2">
                  <img src={rightarrow} alt="" className=" h-[10px]" />
                </button>
              </div>
            </div>

            <div className="flex md:space-x-4 overflow-x-auto scrollbar-hide block md:flex-row py-5 ">
              {stories.map((story) => (
                <div
                  key={story.id}
                  className="flex-shrink-0 w-[22rem] h-[10rem] relative rounded-3xl bg-cover bg-center"
                  style={{ backgroundImage: `url(${story.image})` }}
                >
                  <div className="absolute inset-0 bg-cover bg-center">
                    <span className="absolute text-sm font-semibold text-white p-4">
                      <CategoryPolls title={story.categories} />
                    </span>
                    <div className="absolute inset-0  bg-opacity-40 flex flex-col justify-end">
                      <div className="flex flex-col p-4">
                        <h3
                          className="subtitle_shown mt-2"
                          style={{ color: "#fff" }}
                        >
                          {story.title}
                        </h3>
                        <span
                          className="date_shown mt-4"
                          style={{ color: "#fff" }}
                        >
                          {story.date}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          {/* latest polls end */}

          <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5   md:block">
            <div className=" h-auto pb-14 ">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row justify-between items-center">
                  <div className="text-[16px]">
                    <CategoryPolls title={"US Polls"} />
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <div className="text-center text-[12px]">POLL TYPE</div>
                    <select
                      className="bg-[#E2E7EE] rounded-xl px-2 py-1 text-[12px]"
                      onChange={(e) => setVoteType(e.target.value)}
                    >
                      <option className="text-[#000] px-2" value="popular">
                        Popular Vote
                      </option>
                      <option
                        className="text-[#000] px-2"
                        value="Electoral Votes"
                      >
                        Electoral Votes
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <UsePollPredictionComp type={voteType} />
            </div>
            <div className=" h-auto mt-10">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row justify-between items-center">
                  <div className="text-[16px]">Polling Chart</div>
                </div>
              </div>
              {/* polling chart start */}
              <PollingChart />

              {/* polling chart end */}
              <div className="flex flex-col gap-3">
                <div className="text-[16px]">Polling Table</div>

                <PollingTable />
              </div>
              <div className="py-6">
                <div className="text-[12px] text-gray-700 font-semibold ">
                  Summary
                </div>
                <p className="text-[12px] text-gray-500 py-2 leading-5 text-justify">
                  The race between Joe Biden and Donald Trump is very close.
                  Over the first three months of 2024 the candidates were never
                  more than three points apart in our average of national polls,
                  with Mr Trump narrowly ahead for most of that time. That is
                  new for Mr Trump: in his two previous presidential campaigns
                  he never led a general-election polling average for a single
                  day. More worrying still for Mr Biden, Mr Trump is ahead in
                  several of the swing states that he lost in 2020. The outcome
                  in half a dozen states— Arizona, Georgia, Michigan, Nevada,
                  Pennsylvania and Wisconsin—is likely to prove decisive. A
                  small but critical slice of voters who plumped for Mr Biden
                  back then are now telling pollsters that they plan to defect.
                  Who are they? To find out, we built a statistical model to
                  assess how a hypothetical voter might cast a ballot, based on
                  their demographic traits. Our data come from YouGov, an online
                  pollster, which every week surveys over a thousand people
                  about their demographic profile, voting history and voting
                  intentions. We combined all its survey results since January
                  2023 to get a detailed portrait of Americans’ voting
                  preferences. Use the drop-down menus below to plug in any
                  combination of attributes—age, sex, religion and more—to
                  construct a hypothetical American and see our estimate of
                  their vote. Or press shuffle to see a voter at random. Our
                  model will continuously update to incorporate each week’s
                  YouGov survey.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* blogs start */}
        {/* <div className="bg_home px-6 md:px-40">
          <div className="bg-white  rounded-xl shadow-sm p-3 md:p-5 ">
           
            <SecHead title={"Blogs"} />
            <div className="max-h-[930px] md:max-h-[900px] ">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {posts.map((item) => (
                  <div key={item.id} className="flex flex-col  gap-4 py-4 ">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="w-full h-35 object-cover rounded-md"
                    />
                    <div className="flex flex-col mt-2 md:mt-0 opacity-80 items-start ">
                   
                      <CategoryPolls title={item.category} />
                      <span className=" mt-1 leading-[12px] text-[12px] open-sans-600  tracking-tight"  style={{lineHeight:1.4}}>
                        {item.title}
                      </span>
                      <span className=" date_shown mt-4">{item.date}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div> */}
        {/* blogs end */}
      </div>
    </>
  );
}
export default Polling;
