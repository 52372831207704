import { Link, useLocation } from "react-router-dom";

function TermCondition() {
  const location = useLocation();
  window.scrollTo(0, 0);

  // console.log("location",location.pathname);

  const isVerified = localStorage.getItem("accessToken");
  const breadCumb = [
    { id: 1, name: isVerified ? "For You " : "News ", link: "/" },
    {
      id: 2,
      name: "Term & Condition",
      link: `${location?.pathname}`,
    },
  ];
  return (
    <>
      <div className="bg_home md:bg-[#F7F8FA]  px-0 min-[840px]:px-40 flex flex-col min-[840px]:pb-14 md:py-0">
        <div className="flex flex-row items-center py-5">
          {breadCumb.map((item, index) => (
            // console.log("item",item),

            <span
              key={item.id}
              className={`text-[10px] font-medium  ${
                index === breadCumb.length - 1 ? "" : "opacity-60"
              }`}
            >
              {index > 0 && <span className="mx-1">&gt;</span>}
              <Link to={item?.link} className="breadcrumb-link">
                {item.name}
              </Link>
            </span>
          ))}
        </div>
        <div className="md:bg-[#FFFFFF] w-full  md:round-lg">
          <div className="bg-white w-full  p-3 md:p-5 md:round-lg min-h-[10rem] font-sans">
            <h3 className="  text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A] hidden md:block mb-3">
              Term & Condition
            </h3>
            <div className=" mt-2">
              <p className="text-[21px] font-semibold my-2">
                1. Acceptance of Terms
              </p>
              <p className="my-1">
                Welcome to Opinions and Ratings platform. By accessing or using
                our website, you agree to be bound by these Terms of Use and
                our 
                <a href="/privacy_policy" className="font-bold">
                  Privacy Policy
                </a>{" "}
                . If you do not agree to these terms, please do not use our
                services.
              </p>
              <p className="text-[21px] font-semibold ">
                2. Services Description
              </p>
              <p className="my-1">
                Opinions and Ratings provides content and insights related to
                Global political developments. These services are subject to
                change or termination by Opinions and Ratings at any time,
                without notice. You must meet the minimum age requirement in the
                country of your residence to use these services. In the event
                that any of our service categories are banned in your country of
                residence, you are liable for any violations of laws in that
                country.
              </p>
              <p className="text-[21px] font-semibold ">3. User Conduct</p>
              <p className="my-1">
                You agree to use the website only for lawful purposes and in a
                way that does not infringe the rights of, restrict, or inhibit
                anyone else’s use and enjoyment of the website. Prohibited
                behavior includes harassing or causing distress or inconvenience
                to any other user, transmitting obscene or offensive content, or
                disrupting the normal flow of dialogue within the website.
              </p>
              <p className="text-[21px] font-semibold ">
                4. Intellectual Property
              </p>
              <p className="my-1">
                All content included on the website, such as text, graphics,
                logos, images, as well as the compilation thereof, and any
                software used on the website, is the property of Opinions and
                Ratings or its suppliers and protected by copyright and
                intellectual property laws. In the case of polls, we often quote
                3rd party providers who are both the owners and accountable for
                their forecasts. 3rd party polls are made available as an
                exclusive service to you after registration on the website.
              </p>
              <p className="text-[21px] font-semibold ">
                5. Disclaimer of Warranties
              </p>
              <p className="my-1">
                The website is provided on an “as is” and “as available” basis.
                Opinions and Ratings makes no warranties, express or implied, as
                to the operation of the website or the information, content,
                materials, or products included on it.
              </p>
              <p className="text-[21px] font-semibold ">
                6. Limitation of Liability
              </p>
              <p className="my-1">
                Opinions and Ratings shall not be liable for any damages of any
                kind arising from the use of this website, including, but not
                limited to direct, indirect, incidental, punitive, and
                consequential damages. We strongly recommend you do your own
                research and consult a practicing investment advisor before
                making an Investment decision.
              </p>
              <p className="text-[21px] font-semibold ">
                7. Changes to Terms of Use
              </p>
              <p className="my-1">
                Opinions and Ratings reserves the right to change or modify
                these terms at any time. Your continued use of the site
                following the posting of changes or modifications will
                constitute your acceptance of such changes or modifications.
              </p>
              <p className="text-[21px] font-semibold ">8. Governing Law</p>
              <p className="my-1">
                These terms shall be governed by and construed in accordance
                with the laws of the jurisdiction in which Opinions and Ratings
                is established, without giving effect to any principles of
                conflicts of law.
              </p>
              <p className="text-[21px] font-semibold ">
                9. Contact Information
              </p>
              <p className="my-1">
                For any questions or comments regarding these Terms of Use, 
                <a href="/contact_us" className="font-bold">
                  please contact us
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TermCondition;
