import alerticon from "../../assets/icons/Path 23108.svg";
function VoteShare() {
  const voteshare = [
    { id: 1, name: "Biden", bgColor: "#4252A8" },
    { id: 2, name: "Trump", bgColor: "#E60A00" },
    { id: 3, name: "Kennedy", bgColor: "#FFB199" },
    { id: 4, name: "Tim Scott", bgColor: "#7F8FA4" },
  ];
  return (
    <div className=" my-4 w-full  md:w-3/4">
      <div className="  rounded-md flex gap-2 items-center justify-start cursor-pointer border-none bg-[#F7F8FA]   ">
        <div className="text-[10px] ms-2 flex flex-row gap-2 w-auto">
          <img src={alerticon} alt="alert icon" className="h-4 w-4" />
          <span className=" "> Key States Only</span>
        </div>{" "}
        <div className="flex flex-row gap-2  text-center pt-2">
          <div className="text-[10px] ms-2">
            <div className="flex flex-row gap-4">
              {voteshare.map((ele) => (
                <div key={ele.id} className="flex flex-col text-center">
                  <div
                    className="py-1 px-4"
                    style={{ backgroundColor: ele.bgColor }}
                  >
                    <span className="text-[#fff] font-semibold text-[12px]">
                      {ele.id}
                    </span>
                  </div>
                  <div className="py-1 text-center font-semibold">
                    {ele.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VoteShare;
