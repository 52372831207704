import React from "react";

const DetailShimmer = () => {
  return (
    <>
      <div className="flex items-end gap-2">
        <div className="skeleton-loader h-4 w-5"></div>
        <div className="skeleton-loader h-2 w-12 rounded-md"></div>
      </div>
      <div className="skeleton-loader h-7 w-3/4 rounded-md mt-5 mb-2"></div>
      <div className="skeleton-loader h-7 w-2/4 rounded-md mt-3 mb-5"></div>
      <div className="skeleton-loader h-2 w-24 rounded-md mt-5 mb-5"></div>
      <div className="skeleton-loader h-[20rem] w-full rounded-md"></div>
      <div className="skeleton-loader h-3 w-full rounded-md mb-2 mt-8 "></div>
      <div className="skeleton-loader h-3 w-full rounded-md my-4 "></div>
      <div className="skeleton-loader h-3 w-full rounded-md my-4 "></div>
      <div className="skeleton-loader h-3 w-full rounded-md my-4 "></div>
      <div className="skeleton-loader h-3 w-full rounded-md my-4 "></div>
      <div className="skeleton-loader h-3 w-2/4 rounded-md my-4 "></div>
    </>
  );
};

export default DetailShimmer;
