import React from "react";

const Shimmer = () => {
  return (
    <div className="flex flex-col justify-start items-start  px-5 py-2">
      <div className="flex gap-8 ">
        <div className="skeleton-loader h-[9rem] w-[15rem] rounded"></div>
        <div className=" flex justify-between my-1 flex-col">
          <div className="">
            <div className="skeleton-loader h-2 w-[3rem] rounded mb-8"></div>
            <div className="">
              <div className="skeleton-loader h-2 min-w-[20rem] lg:w-[19rem] rounded mb-2"></div>
              <div className="skeleton-loader h-2 w-[8rem] rounded "></div>
            </div>
          </div>
          <div className="skeleton-loader h-2 w-[7rem] rounded "></div>
        </div>
      </div>
    </div>
  );
};

export default Shimmer;
