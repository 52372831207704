import { Link } from "react-router-dom";
import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getPublicLatestPost } from "../../redux/actions/publicAction";

function LatestNews() {
  const dispatch = useDispatch();
  const { latestPost } = useSelector((state) => state.publicReducer);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const isVerified = localStorage.getItem("accessToken");
  const breadCumb = [
    { id: 1, name: isVerified ? "For You" : "News", link: "/" },
    { id: 2, name: "Latest News", link: "/latest-news" },
  ];

  useEffect(() => {
    dispatch(getPublicLatestPost())
      .then(() => setLoading(false))
      .catch((err) => {
        console.error("Failed to fetch posts:", err);
        setError("Failed to load latest news.");
        setLoading(false);
      });
  }, [dispatch]);

  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");

  const cleanString = (str) => str.replace(/[^a-zA-Z0-9-_]/g, "");

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span>Loading...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span>{error}</span>
      </div>
    );
  }

  return (
    <div className="bg_home bg-[#F7F8FA] md:bg-[#ffffff] flex flex-col">
      <div className="">
        <div className="md:hidden my-4 px-4">
          <PreSectionHeader title={"Latest News"} />
        </div>
        <hr className="border-b-1 md:border-none mt-3 md:mt-0 font-semibold" />

        {/* Breadcrumb */}
        <div className="bg_home md:bg-[#F7F8FA] px-0 min-[840px]:px-40 flex flex-col min-[840px]:pb-14 md:p-0">
          <div className="flex flex-row items-center py-5">
            {breadCumb?.map((item, index) => (
              <span
                key={item.id}
                className={`text-[10px] font-medium ${
                  index === breadCumb?.length - 1 ? "" : "opacity-60"
                }`}
              >
                {index > 0 && <span className="mx-1">&gt;</span>}
                <Link
                  to={item?.link}
                  className="breadcrumb-link"
                  aria-label={`Navigate to ${item.name}`}
                >
                  {item.name}
                </Link>
              </span>
            ))}
          </div>

          {/* News Section */}
          <div className="md:bg-[#FFFFFF] w-full md:rounded-lg">
            <div className="bg-white w-full p-3 md:p-5 md:rounded-lg">
              <h3 className="font-sans text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A] hidden md:block">
                Latest News
              </h3>
              <div>
                {Array.isArray(latestPost) &&
                  latestPost.map((post) => (
                    <Link
                      to={`/latest-news/${createSlug(post?.title)}`}
                      key={post?.id}
                      className="flex flex-col md:flex-row items-start gap-5 py-4 relative"
                    >
                      {post?.file && (
                        <img
                          loading="lazy"
                          src={post?.file}
                          alt={post?.title || "News image"}
                          className="w-full md:w-[14rem] h-36 object-cover rounded-md"
                        />
                      )}
                      <div className="flex flex-col gap-1">
                        <CategoryPolls
                          key={post?.category?.id}
                          title={post?.category?.name}
                          postid={post?.category?.id}
                          img={post?.category?.icon}
                        />
                        <span
                          className="font-sans text-[#1A1A1A] open-sans-600 tracking-tight"
                          style={{ lineHeight: 1.4 }}
                        >
                          {/* {cleanString(post?.title)} */}
                          {post?.title}
                        </span>
                        <span className="date_shown relative md:absolute md:bottom-3">
                          {moment(post?.updatedAt).format("MMMM D, YYYY")}
                        </span>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestNews;
