import { Link, useParams } from "react-router-dom";
import WithLayout from "../../components/Layout/Layout";
import LocalNews from "../../components/HomeComp/LocalNewsHomeComp";
import TopStoriesDetailsCom from "../../components/HomeComp/TopStoriesDetailsCom/TopStoriesDetailsCom";
import { useEffect, useState } from "react";
import TopStoriesDetailsMobileCom from "../../components/HomeComp/TopStoriesDetailsCom/TopStoriesDetailsMobileCom";
import { getPublicLatestPost } from "../../redux/actions/publicAction";
import { useDispatch, useSelector } from "react-redux";
import SecHead from "../../components/SectionHeader/SecHead";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
import moment from "moment";
import LatestNewsBottom from "../../components/commonComponenets/LatestNewsBottom";

function ForYouDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  // console.log("id",id);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const { latestPost } = useSelector((state) => state.publicReducer);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    dispatch(getPublicLatestPost(3));
  }, []);

  return (
    <>
      <div className="bg_home  bg-[#F7F8FA] md:px-40 flex flex-col pb-14">
        <div className="flex-[2] ">
          {isMobile ? (
            <div className="flex flex-col  gap-5 ">
              <div className=" flex flex-col ">
                <TopStoriesDetailsMobileCom postDetailsid={id} />
              </div>
              {/* 
          <div className="flex-1 flex flex-col gap-5">
            <LocalNews />
           
          </div> */}
            </div>
          ) : (
            <div className="flex min-[1040px]:flex-row flex-col  gap-5 mb-5  overflow-auto">
              <div className="flex-[2] flex flex-col gap-5 min-[1040px]:w-3/5">
                <TopStoriesDetailsCom postDetailsid={id} />
              </div>

              <div className="flex-1 flex flex-col gap-5">
                <LocalNews />
              </div>
            </div>
          )}

          {/* latest post common componenet */}
          <latestPost />
        </div>
      </div>
    </>
  );
}
ForYouDetails.Layout = WithLayout;
export default ForYouDetails;
